import {
  _getDocumentLocale,
  _getProfileFieldAtKeyPath
} from './formatters-internal.js';

export function longDate(profile, keyPath) {
  const dateString = _getProfileFieldAtKeyPath(profile, keyPath);
  if (!dateString) {
    return '';
  }
  const parsedDateString = dateString + 'T00:00:00';
  const date = new Date(parsedDateString);
  const locale = _getDocumentLocale();
  if (!dateString) {
    return '';
  } else {
    return {
      day: date.toLocaleString(locale, {day: 'numeric'}),
      month: date.toLocaleString(locale, { month: 'long'}),
      year: date.toLocaleString(locale, {year: 'numeric'}),
    }
  }
}
